import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { routes } from './routes/app.routes';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import { authInterceptor } from '@shared/auth/auth.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
    importProvidersFrom(
      OktaAuthModule.forRoot({
        oktaAuth: new OktaAuth({
          clientId: environment.oktaConfig.clientId,
          issuer: environment.oktaConfig.issuer,
          redirectUri: environment.oktaConfig.redirectUri,
          postLogoutRedirectUri: environment.oktaConfig.postLogoutRedirectUri,
          scopes: ['openid', 'profile', 'email'],
          pkce: true,
          tokenManager: {
            autoRenew: true,
            autoRemove: false,
          },
        }),
      }),
    ),
    provideRouter(routes),
  ],
};
