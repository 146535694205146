import { Injectable, inject } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { filter, map, Observable } from 'rxjs';
import { Router } from '@angular/router';

export class AuthError extends Error {
  constructor(
    message: string,
    public originalError?: any,
  ) {
    super(message);
    this.name = 'AuthError';
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private oktaStateService = inject(OktaAuthStateService);
  private oktaAuth = inject(OKTA_AUTH);
  private router = inject(Router);

  constructor() {
    this.handleExpiredToken();
  }

  public isAuthenticated$: Observable<boolean> =
    this.oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false),
    );

  public async signIn(): Promise<void> {
    try {
      await this.oktaAuth.signInWithRedirect();
    } catch (error) {
      throw new AuthError('Failed to sign in', error);
    }
  }

  public async signOut(): Promise<void> {
    try {
      await this.oktaAuth.signOut();
      await this.router.navigate(['/loggedout']);
    } catch (error) {
      throw new AuthError('Failed to sign out', error);
    } finally {
      localStorage.removeItem('okta-token-storage');
      sessionStorage.clear();
    }
  }

  public async getUser() {
    try {
      const user = await this.oktaAuth.getUser();
      if (!user) {
        throw new AuthError('No user found');
      }
      return user;
    } catch (error) {
      throw new AuthError('Failed to get user information', error);
    }
  }

  private handleExpiredToken() {
    this.oktaAuth.tokenManager.on('expired', async (key) => {
      if (key === 'refreshToken') {
        await this.signOut();
      } else {
        try {
          await this.oktaAuth.tokenManager.renew('accessToken');
        } catch (error) {
          await this.signOut();
        }
      }
    });
  }
}
